/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "fonts/mabry-pro.css";
@import "fonts/nouvelle.css";
@import "fonts/charter.css";

@layer base {
  :root {
    --yellow-pale: #ffd063;
  }
  html {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
  }
}
/* 
 * We can add custom styling classes down here like:
 *  .error {
 *    @apply bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative;
 *  }
 */
