@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskThin/NNNouvelleGrotesk-Thin.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskThin/NNNouvelleGrotesk-Thin.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskThin/NNNouvelleGrotesk-Thin.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskThinItalic/NNNouvelleGrotesk-ThinDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskThinItalic/NNNouvelleGrotesk-ThinDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskThinItalic/NNNouvelleGrotesk-ThinDiagonal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskLight/NNNouvelleGrotesk-Light.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskLight/NNNouvelleGrotesk-Light.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskLight/NNNouvelleGrotesk-Light.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 300;
  src: url("/fonts/nouvelle/NNNouvelleGroteskLightItalic/NNNouvelleGrotesk-LightDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskLightItalic/NNNouvelleGrotesk-LightDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskLightItalic/NNNouvelleGrotesk-LightDiagonal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskNormal/NNNouvelleGrotesk-Normal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskNormal/NNNouvelleGrotesk-Normal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskNormal/NNNouvelleGrotesk-Normal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskNormalItalic/NNNouvelleGrotesk-NormalDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskNormalItalic/NNNouvelleGrotesk-NormalDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskNormalItalic/NNNouvelleGrotesk-NormalDiagonal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskMedium/NNNouvelleGrotesk-Medium.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskMedium/NNNouvelleGrotesk-Medium.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskMedium/NNNouvelleGrotesk-Medium.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskMediumItalic/NNNouvelleGrotesk-MediumDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskMediumItalic/NNNouvelleGrotesk-MediumDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskMediumItalic/NNNouvelleGrotesk-MediumDiagonal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskBold/NNNouvelleGrotesk-Bold.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskBold/NNNouvelleGrotesk-Bold.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskBold/NNNouvelleGrotesk-Bold.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskBoldItalic/NNNouvelleGrotesk-BoldDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskBoldItalic/NNNouvelleGrotesk-BoldDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskBoldItalic/NNNouvelleGrotesk-BoldDiagonal.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskBlack/NNNouvelleGrotesk-Black.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskBlack/NNNouvelleGrotesk-Black.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskBlack/NNNouvelleGrotesk-Black.otf")
      format("opentype");
}

@font-face {
  font-family: "Nouvelle Grotesk";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/nouvelle/NNNouvelleGroteskBlackItalic/NNNouvelleGrotesk-BlackDiagonal.woff2")
      format("woff2"),
    url("/fonts/nouvelle/NNNouvelleGroteskBlackItalic/NNNouvelleGrotesk-BlackDiagonal.woff")
      format("woff"),
    url("/fonts/nouvelle/NNNouvelleGroteskBlackItalic/NNNouvelleGrotesk-BlackDiagonal.otf")
      format("opentype");
}
