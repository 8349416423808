@font-face {
  font-family: "Charter";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProRegular/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProRegular/font.woff") format("woff");
}

@font-face {
  font-family: "Charter";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProItalic/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProItalic/font.woff") format("woff");
}

@font-face {
  font-family: "Charter";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProBold/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProBold/font.woff") format("woff");
}

@font-face {
  font-family: "Charter";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProBoldItalic/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProBoldItalic/font.woff") format("woff");
}

@font-face {
  font-family: "Charter";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProBlack/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProBlack/font.woff") format("woff");
}

@font-face {
  font-family: "Charter";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("/fonts/charter/CharterITCProBlackItalic/font.woff2") format("woff2"),
    url("/fonts/charter/CharterITCProBlackItalic/font.woff") format("woff");
}
