@font-face {
  font-family: "Mabry Pro";
  font-style: normal;
  font-weight: 200;
  font-display: swap;
  src: url("/fonts/mabry-pro/mabry-light-pro-web/mabry-light-pro.eot");
  src: url("/fonts/mabry-pro/mabry-light-pro-web/mabry-light-pro.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/mabry-pro/mabry-light-pro-web/mabry-light-pro.woff2")
      format("woff2"),
    url("/fonts/mabry-pro/mabry-light-pro-web/mabry-light-pro.woff")
      format("woff"),
    url("/fonts/mabry-pro/mabry-light-pro-web/mabry-light-pro.ttf")
      format("truetype");
}

@font-face {
  font-family: "Mabry Pro";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/mabry-pro/mabry-regular-pro-web/mabry-regular-pro.eot");
  src: url("/fonts/mabry-pro/mabry-regular-pro-web/mabry-regular-pro.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/mabry-pro/mabry-regular-pro-web/mabry-regular-pro.woff2")
      format("woff2"),
    url("/fonts/mabry-pro/mabry-regular-pro-web/mabry-regular-pro.woff")
      format("woff"),
    url("/fonts/mabry-pro/mabry-regular-pro-web/mabry-regular-pro.ttf")
      format("truetype");
}

@font-face {
  font-family: "Mabry Pro";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/fonts/mabry-pro/mabry-medium-pro-web/mabry-medium-pro.eot");
  src: url("/fonts/mabry-pro/mabry-medium-pro-web/mabry-medium-pro.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/mabry-pro/mabry-medium-pro-web/mabry-medium-pro.woff2")
      format("woff2"),
    url("/fonts/mabry-pro/mabry-medium-pro-web/mabry-medium-pro.woff")
      format("woff"),
    url("/fonts/mabry-pro/mabry-medium-pro-web/mabry-medium-pro.ttf")
      format("truetype");
}

@font-face {
  font-family: "Mabry Pro";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/fonts/mabry-pro/mabry-bold-pro-web/mabry-bold-pro.eot");
  src: url("/fonts/mabry-pro/mabry-bold-pro-web/mabry-bold-pro.eot?#iefix")
      format("embedded-opentype"),
    url("/fonts/mabry-pro/mabry-bold-pro-web/mabry-bold-pro.woff2")
      format("woff2"),
    url("/fonts/mabry-pro/mabry-bold-pro-web/mabry-bold-pro.woff")
      format("woff"),
    url("/fonts/mabry-pro/mabry-bold-pro-web/mabry-bold-pro.ttf")
      format("truetype");
}
